.davexplorerview {
  display: grid; 
  grid-template-columns: 1fr; 
  height: 100vh; 
  max-height: 100vh;
}

.davsidebar {
  display: none
}

.davphotoviewicon {
  width: 16px;
  height: 16px;
  color: #d8dae5;
}

.davbreadcrumbmenu {
  display: grid;
}

.davbreadcrumb {
  display: none;
}

.tablecell.smallhidden {
  display: none;
}

.card-file {
  width: 120px;
  height:120px; 
}

@media screen and (min-width: 950px) {
  .davsidebar {
    display: grid;
  }

  .cool-scrollbars::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .cool-scrollbars::-webkit-scrollbar-track {
    display:none;
  }

  .cool-scrollbars::-webkit-scrollbar-corner {
    background: transparent;
  }

  .cool-scrollbars::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .cool-scrollbars:hover::-webkit-scrollbar-thumb {
    background-color: #696f8c;
    background-clip: padding-box;
    border-radius: 20px;
  }
 
  .cool-scrollbars::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  .davexplorerview {
    grid-template-columns: 1fr 5fr;
  }

  .davbreadcrumbmenu {
    display: none;
  }

  .davbreadcrumb {
    display: flex
  }

  .davbreadcrumb::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
  }

  .davtree {
    scrollbar-width: thin;
  }

  .largehidden {
    display: none;
  }

  .davphotoviewicon {
    width: 36px;
    height: 36px;
  }

  .tablecell.smallhidden {
    display: flex;
  }

  .card-file {
    width: 150px;
    height:150px; 
  }
}