
.loginviewpane {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    padding: 10px;
}


@media screen and (min-width: 950px) {
    .loginviewpane {
        padding: 20px;
        position: relative;
        top: 25px;
        left: calc((100vw - 390px)/2);
        max-height: 480px;
        min-width: 390px;
        max-width: 390px;
    }
}